import $ from "jquery";
import { tns } from "./../../node_modules/tiny-slider/src/tiny-slider";
import { format, render, cancel, register } from 'timeago.js';
import { validateEmail, isMobile, isMobileTabletBySize } from './helpers/index.js'

if ($('.time-ago').length > 0) {
  const dateObject = new Date($('.time-ago').data('time'));
  let lang = 'en_US';
  if (currentLang === 'nl') {
    lang = 'nl_NL';
  } else if (currentLang === 'de' ) {
    lang = 'de_DE';
  }
  $('.time-ago').text(format(dateObject, lang));
}

$(window).scroll(function () {
  var size = $(window).width();
  var scroll = $(window).scrollTop();
      if (scroll < this.lastScroll) {
        $(".header").removeClass("up");
        if ($('.header').hasClass('orange') || $('.header').hasClass('blue')) {
          $('.white-logo').removeClass('hidden')
          $('.orange-logo').addClass('hidden')
        }
      } else {
        $(".header").addClass("up");
        $(".header").removeClass("hover");
        $(".mega-menu-item-has-children").removeClass('mega-submenu-open')
        $(".mega-menu-item-has-children").removeClass('mega-toggle-on')
      }
      this.lastScroll = scroll;
});

let currentActiveMenu;
let firstDelay = 500;
let hoverDelayApplied = false;
$('.mega-menu-item-has-children').hover(
  function (e) {
    if ($(window).width() > 768) {
      triggerMenuStyle($(this))
    } else {
      if ($(this).hasClass('mega-submenu-open')) {
        $(this).removeClass('mega-submenu-open')
        $(this).removeClass('mega-toggle-on')
      } else {
        $(this).addClass('mega-submenu-open')
        $('.mobile-lang-list').show()
      }
    }
  }, function (e) {
    if ($(window).width() > 768) {
      triggerMenuStyle($(this))
    } else {
      if ($(this).hasClass('mega-submenu-open')) {
        $(this).removeClass('mega-submenu-open')
        $(this).removeClass('mega-toggle-on')
      } else {
        $(this).addClass('mega-submenu-open')
      }
    }
  }
);


jQuery(document).ready(function ($) {
  $('.load-more-news').click(function () {
    var button = $(this),
      data = {
        'action': 'load_posts_by_ajax',
        'page': button.data('page'),
      };

    $.post(ajaxUrl, data, function (response) {
      if ($.trim(response) != '') {
        $('#post-container').append(response);
        let currentPage = button.data('page') + 1
        button.data('page', currentPage);

        if(currentPage === button.data('total-page')) {
          button.hide();
        }
      } else {
        button.hide();
      }
    });
  });

  $('.load-more-events-webinars').click(function () {
    var button = $(this),
      data = {
        'action': 'load_events_webinars_by_ajax',
        'page': button.data('page'),
      };

    $.post(ajaxUrl, data, function (response) {
      if ($.trim(response) != '') {
        $('#post-container').append(response);
        let currentPage = button.data('page') + 1
        button.data('page', currentPage);

        if (currentPage === button.data('total-page')) {
          button.hide();
        }
      } else {
        button.hide();
      }
    });
  });

  $('.load-more-clients').click(function () {
    var button = $(this),
      data = {
        'action': 'load_clients_by_ajax',
        'page': button.data('page'),
      };

    $.post(ajaxUrl, data, function (response) {
      if ($.trim(response) != '') {
        $('#post-container').append(response);
        let currentPage = button.data('page') + 1
        button.data('page', currentPage);

        if (currentPage === button.data('total-page')) {
          button.hide();
        }
      } else {
        button.hide();
      }
    });
  });
});

$('.mega-menu-toggle').click(function () {
  if ($(window).width() <= 768) {
    triggerMenuStyle($(this))
    $('.mobile-lang-list').toggle()
    $('.mobile-lang-list').toggle()
  }
});

$(window).resize(function () {
  if ($(window).width() > 768) {
    $('.mobile-lang-list').hide()
    $('.header').removeClass('hover')
    $('.mega-menu-item-has-children').removeClass('mega-toggle-on')
    if ($('.header').hasClass('orange') || $('.header').hasClass('blue')) {
      $('.orange-logo').addClass('hidden')
      $('.white-logo').removeClass('hidden')
    }
    if ($(this).hasClass('mega-submenu-open')) {
      $(this).removeClass('mega-submenu-open')
      $(this).removeClass('mega-toggle-on')
    } else {
      $(this).addClass('mega-submenu-open')
    }
  }
});

function triggerMenuStyle($this) {
  if ($('.header').hasClass('hover')) {
    if($this.attr('id') === currentActiveMenu) {
      $('.header').removeClass('hover')
      $('.mega-menu-item-has-children').removeClass('mega-toggle-on')
      if ($('.header').hasClass('orange') || $('.header').hasClass('blue')) {
        $('.orange-logo').addClass('hidden')
        $('.white-logo').removeClass('hidden')
      }
    } else {
      currentActiveMenu = $this.attr('id')
    }

  } else {
    $('.header').addClass('hover')
    $this.children('.mega-sub-menu').attr('style', 'display:block!important')

    currentActiveMenu = $this.attr('id')
    if ($('.header').hasClass('orange') || $('.header').hasClass('blue')) {
      $('.orange-logo').removeClass('hidden')
      $('.white-logo').addClass('hidden')
    }
  }
}

$('.current-lang').click(function () {
  $('.header').removeClass('hover');
  if ($('.header').hasClass('orange') || $('.header').hasClass('blue')) {
    $('.white-logo').removeClass('hidden')
    $('.orange-logo').addClass('hidden')
  }
  $(this).toggleClass('active');
});


$('.accordion .item').click(function () {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
    $(this).find('p').addClass('hidden');
  } else {
    $('.accordion .item').removeClass('active');
    $('.accordion .item').find('p').addClass('hidden');
    $(this).addClass('active');
    $(this).find('p').removeClass('hidden');
  }
});

$('.copy-link').click(function () {
  navigator.clipboard.writeText(window.location.href);
  $('.links-copied').removeClass('hidden')

  setTimeout(() => {
    $('.links-copied').addClass('hidden')
  }, "1000");
});

if ($('.discover-block').length > 0) {
  var discoverSlider = tns({
    container: '.discover-slider',
    items: 1,
    edgePadding: 0,
    slideBy: 'page',
    autoplay: false,
    mouseDrag: true,
    autoWidth: false,
    loop: true,
    controls: false,
    gutter: 24,
    "startIndex": 0,
    "swipeAngle": false,
    controlsText: ["", ""],
    "startIndex": 0,
    responsive: {
      "1024": {
        autoWidth: true,
        items: 3,
      },
    }
  });

  document.querySelector('.discover-slider-next').onclick = function () {

    var info = discoverSlider.getInfo(),
      indexPrev = info.indexCached,
      indexCurrent = info.index;

    discoverSlider.goTo(1);
  };

  document.querySelector('.discover-slider-prev').onclick = function () {

    var info = discoverSlider.getInfo(),
      indexPrev = info.indexCached,
      indexCurrent = info.index;

    if (indexCurrent > 0) {
      $('.discover-block .slider-prev').removeClass('disable')
    }

    if (indexCurrent == 0) {
      $('.discover-block .slider-prev').addClass('disable')
    }

    if (indexCurrent >= info.slideItems.length - 1) {
      $('.discover-block .slider-next').addClass('disable')
    }

    if (indexCurrent < info.slideItems.length - 1) {
      $('.discover-block .slider-next').removeClass('disable')
    }

    discoverSlider.goTo('prev');
  };
};



if ($('.clients-block').length > 0) {
   tns({
    container: '.logo-slider',
    items: 3,
    edgePadding: 0,
    slideBy: 'page',
    autoplay: false,
    mouseDrag: true,
    autoWidth: false,
    loop: true,
    controls: false,
    gutter: 24,
    "startIndex": 0,
    navContainer: false,
    "swipeAngle": false,
    controlsText: ["", ""],
    "startIndex": 0,
    responsive: {
      "1024": {
        autoWidth: true,
        items: 5,
      },
    }
  });
};
